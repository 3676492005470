import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  correctAnswerValidator,
  answerLengthValidator,
  quizModulesValidator,
  answerDesirabilityValidator,
  imageOrQuestionValidator,
  maxNumberOfAnswersCandidateCanChooseValidator
} from 'src/app/validators/quiz-question.validator';
import { gsmCharsetValidationRegex } from 'src/app/resources/regex-validators';
import { QUIZ_MODULES_ARRAY } from 'src/app/resources/quiz-modules';
import { blankCharacterValidation } from 'src/app/validators/blank-character.validator';
import { correctActivDate } from 'src/app/validators/correct-date.validator';
import { Answer, InhouseQuiz, Question, Quiz, TypeOfSkillsQuestion, VideoQuestion } from 'src/app/model/job.interface';
import { maxVideoMainMessageLength, maxVideoMessageLength } from './video-intro-content';
import { INHOUSE_QUESTION_CONTENT } from './inhouse-question-content';
import { TranslationKeys } from '../model/translation-object.interface';
import { Trigger } from '../model/trigger.interface';
import { requireCheckboxesToBeCheckedValidator } from '../validators/minimum-checked.validator';
import { SetupService } from '../services/setup.service';

const fb = new FormBuilder();
const smsValidationPattern = gsmCharsetValidationRegex;

export const maxIntroMessageLength = 100;
export const maxIntroMainMessageLength = 300;
export const maxReminderSmsLength = 133;
export const costCenterMaxLength = 65500;
export const tagMaxLength = 255;

export function initializeForm(): FormGroup {
  return fb.group({
    jobInfo: fb.group(
      {
        jobTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
        branch: fb.control(null, [Validators.required]),
        jobLocation: fb.control(null),
        applicationDate: fb.control(null, [Validators.required]),
        responsiblePerson: fb.control(null, [Validators.required]),
        status: fb.control({value: null, disabled: true}, [Validators.required]),
        isTemplate: fb.control(false),
        contactDetail: fb.control(false),
        language: fb.control(null),
        notificationEnabled: fb.control(false),
        hiddenFromUsers: fb.control(null),
        triggers: fb.array([]),
        excludedJobTriggersHiringStatusIds: fb.control([]),
        customersInChargeOfJob: fb.control(null)
      },
      { validators: [correctActivDate] }
    ),
    jobDetails: fb.group({
      video: fb.control(null, [Validators.required]),
      picture: fb.control(null, [Validators.required]),
      jobDescription: fb.control(null, [
        Validators.required,
        blankCharacterValidation,
      ]),
      jobDescriptionTemplate: fb.control(null, Validators.required),
      shortJobDescription: fb.control(null, [
        blankCharacterValidation
      ]),
      shortJobDescriptionTemplate: fb.control(null),
      reminderSms: fb.control('', [
        Validators.required,
        Validators.maxLength(maxReminderSmsLength),
        Validators.pattern(smsValidationPattern),
      ]),
      introMessage: fb.control('', [
        Validators.required,
        Validators.maxLength(maxIntroMessageLength),
      ]),
      introMainMessage: fb.control('', [
        Validators.required,
        Validators.maxLength(maxIntroMainMessageLength),
      ]),
      thankYouMessage: fb.control('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      thankYouMainMessage: fb.control('', [
        Validators.required,
        Validators.maxLength(300)
      ]),
      sendReminderSms: fb.control(true),
    }),
    jobPublish: fb.group({
      jobPlatforms: fb.control([]),
      publishedJobPlatforms: fb.control([]),
      publishOnHigher: fb.control(false),
    }),
    quizModules: fb.group({
      orderOfQuestionsModules: fb.control([], [quizModulesValidator]),
      videoQuestions: fb.group({
        videoMessage: fb.control(null, [Validators.required, Validators.maxLength(maxVideoMessageLength)]),
        videoMainMessage: fb.control(null, [Validators.required, Validators.maxLength(maxVideoMainMessageLength)]),
        video: fb.control(null),
        questions: fb.array([
          fb.control(null, [Validators.required])
        ]),
        addVideoOptions: fb.group({
          streamVideoAnswers: fb.control(true),
          linkVideoAnswers: fb.control(true),
          uploadVideoAnswers: fb.control(true),
        }, {validators: [requireCheckboxesToBeCheckedValidator()]}),
      }),
      inhouseQuestion: createInhouseQuestionForm(),
      knockoutQuestions: createKnockoutQuestionsForm(),
      businessQuestions: createBusinessQuestionsForm()
    }),
  });
}

export function createBusinessQuestionsForm(): FormGroup {
  return fb.group({
    rejectionMessageMain: ['', [Validators.required]],
    rejectionMessage: ['', [Validators.required]],
    minPercentageForPass: 0,
    questions: fb.array([
      createBusinessQuestion()
    ]),
  });
}

export function createBusinessQuestion(questionType?: string): FormGroup {
  const questionForm: FormGroup = fb.group(
    {
      text: fb.control(null),
      image: fb.control(null),
      video: fb.control(null),
      questionType: fb.control(questionType ?
        questionType : TypeOfSkillsQuestion.singleAnswerQuestion),
      questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose: fb.control(null),
      maxNumberOfAnswersCandidateCanChoose: fb.control(1, [Validators.required]),
      answers: fb.array([
        fb.group({
          text: fb.control(null, [Validators.required]),
          desirability: fb.control(null),
          tag: fb.control(null, [Validators.maxLength(tagMaxLength)])
        }),
      ]),
    },
    {
      validators: [
        answerDesirabilityValidator,
        answerLengthValidator,
        correctAnswerValidator,
        imageOrQuestionValidator,
        correctAnswerValidator,
        maxNumberOfAnswersCandidateCanChooseValidator
      ]
    }
  );

  if (questionType !== TypeOfSkillsQuestion.questionWithFollowUpQuestions) {
    questionForm.addControl('questionImportance', fb.control(2, [Validators.required]));
  }
  return questionForm;
}

export function setBusinessQuestions(question: Question, isFollowing?: boolean): FormGroup {
  const questionFormGroup: FormGroup = fb.group(
    {
      text: fb.control(question.text),
      image: fb.control(question.image),
      video: fb.control(question.video),
      answers: fb.array([]),
      questionType: fb.control(question.questionType ? question.questionType : TypeOfSkillsQuestion.singleAnswerQuestion),
      questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose:
        fb.control(question.questionScoreDividerIsMaxNumberOfAnswersCandidateCanChoose),
      maxNumberOfAnswersCandidateCanChoose:
        fb.control( question.questionType === TypeOfSkillsQuestion.multipleAnswersQuestion ?
                      question.maxNumberOfAnswersCandidateCanChoose : 1),
    },
    {
      validators: [
        answerDesirabilityValidator,
        answerLengthValidator,
        correctAnswerValidator,
        imageOrQuestionValidator,
        correctAnswerValidator,
        maxNumberOfAnswersCandidateCanChooseValidator
      ]
    }
  );

  if (!isFollowing) {
    questionFormGroup.addControl('questionImportance', fb.control(question.questionImportance, [Validators.required]));
  }

  if (question.questionType !== TypeOfSkillsQuestion.freeAnswerQuestion) {
    question.answers.forEach((answer, answerIndex) => {
      const answers = questionFormGroup.get('answers') as FormArray;
      const answerControl: FormGroup = fb.group({
        text: fb.control(answer.text, [Validators.required]),
        desirability: fb.control(answer.desirability),
        tag: fb.control(answer.tag, [Validators.maxLength(tagMaxLength)])
      });

      if (answer.followUpQuestion) {
        answerControl.addControl('followUpQuestion', setBusinessQuestions(answer.followUpQuestion, true));
      }

      answers.insert(answerIndex, answerControl);
    });
  }

  return questionFormGroup;
}

export function createKnockoutQuestionsForm(): FormGroup {
  return fb.group({
    rejectionMessageMain: ['', [Validators.required]],
    rejectionMessage: ['', [Validators.required]],
    passAllCandidates: false,
    questions: fb.array([
      createKnockoutOrInhouseQuestion()
    ]),
  });
}

export function createKnockoutOrInhouseQuestion(): FormGroup {
  return fb.group(
    {
      text: [null, [Validators.required]],
      answers: fb.array([
        fb.group({
          text: [null, [Validators.required]],
          correct: false,
        }),
      ])
    },
    { validators: [correctAnswerValidator, answerLengthValidator] }
  );
}

export function setKnockoutOrInhouseQuestion(question: Question): FormGroup {
  const questionFormGroup: FormGroup = fb.group(
    {
      text: fb.control(question.text, [Validators.required]),
      answers: fb.array([])
    },
    { validators: [correctAnswerValidator, answerLengthValidator] }
  );

  question.answers.forEach((answer: Answer, answerIndex: number) => {
    const answers = questionFormGroup.get('answers') as FormArray;
    const answerControl = fb.group({
      text: fb.control(answer.text, [Validators.required]),
      correct: fb.control(answer.correct),
    });

    answers.insert(answerIndex, answerControl);
  });

  return questionFormGroup;
}

export function createInhouseQuestionForm(): FormGroup {
  return  fb.group({
    passAllInhouseCandidates: false,
    question: createKnockoutOrInhouseQuestion(),
  });
}

export function setInhouseQuiz(form: FormGroup, inhouseQuiz: InhouseQuiz, jobLanguage: TranslationKeys): void {
  let quiz: InhouseQuiz = inhouseQuiz;

  if (!quiz) {
    quiz = setInhouseDefaultQuestion(jobLanguage);
  }

  const isDisabled = form.disabled;

  form.get('passAllInhouseCandidates').setValue(quiz.passAllInhouseCandidates);
  form.setControl('question', setKnockoutOrInhouseQuestion(quiz.question));

  if (isDisabled) {
    form.disable();
  }
}

export function setInhouseDefaultQuestion(language: string): InhouseQuiz {
  const { text, yes, no } = INHOUSE_QUESTION_CONTENT[language];

  const question: Question = {
    text: text,
    answers: [
      {
        text: yes,
        correct: true
      },
      {
        text: no,
        correct: false
      }
    ]
  };

  const quiz = {
    question,
    passAllInhouseCandidates: false
  };

  return quiz;
}

export function setQuiz(form: FormGroup, quiz: Quiz): void {
  const questions = form.get('questions') as FormArray;
  questions.clear();

  form.get('rejectionMessage').setValue(quiz.rejectionMessage);
  form.get('rejectionMessageMain').setValue(quiz.rejectionMessageMain);

  if (quiz.hasOwnProperty('passAllCandidates')) {
    form.get('passAllCandidates').setValue(quiz.passAllCandidates);
  }

  if (quiz.hasOwnProperty('minPercentageForPass')) {
    form.get('minPercentageForPass').setValue(quiz.minPercentageForPass);
  }

  const isDisabled = form.disabled;

  quiz.questions
    .forEach((question: Question) => {
      const questionFormGroup = quiz.hasOwnProperty('passAllCandidates')
                                  ? setKnockoutOrInhouseQuestion(question)
                                  : setBusinessQuestions(question);

      questions.push(questionFormGroup);
    });

  if (isDisabled) {
    form.disable();
  }
}

export function setVideoQuestions(form: FormGroup, videoQuestion: VideoQuestion): void {
  if (videoQuestion) {
    form.patchValue(videoQuestion);

    const questionsFormArray = form.get('questions') as FormArray;
    questionsFormArray.clear();

    videoQuestion.questions
      .forEach((question: string) => {
        const control = fb.control(question || null, [Validators.required]);
        if (questionsFormArray.disabled) {
          control.disable();
        }
        questionsFormArray.push(control);
      });
  }
}

export function disableFormFields(form: FormGroup, fieldNames: string[]): void {
  fieldNames.forEach((fieldName: string) => {
    const formControl = form.get(fieldName);

    if (formControl) {
      formControl.disable();
      if (fieldName !== 'orderOfQuestionsModules' &&
          fieldName !== 'experienceRequired' ) {
        formControl.reset();
      }
    }
  });
}

export function enableFormFields(form: FormGroup, fieldNames: string[]): void {
  fieldNames.forEach((fieldName: string) => {
    const formControl = form.get(fieldName);

    if (formControl) {
      formControl.enable();
    }
  });
}

export function disableInactiveModules(quizModulesFormGroup: FormGroup): void {
  const activeModules = quizModulesFormGroup.get('orderOfQuestionsModules').value;

  QUIZ_MODULES_ARRAY
    .forEach((moduleName: string) => {
      const moduleForm = quizModulesFormGroup.get(moduleName);

      if (!activeModules.find((activeModule: string) => activeModule === moduleName)) {
        moduleForm.disable();
      } else {
        moduleForm.markAllAsTouched();
      }
    });
}

export function createJobTriggerForm(): FormGroup {
  return fb.group({
    applicationHiringStatus: fb.group({
      name: '',
      id: null
    }),
    actionType: [null],
    actionTimeType: [null],
    smsContent: fb.group({
      useUrl: [false],
      content: ['']
    }),
    emailContent: fb.group({
      attachments: null,
      useUrl: false,
      subject: [''],
      content: [''],
      greeting: ['']
    }),
    relativeActionTime: fb.group({
      day: [0],
      hour: [0]
    }),
    actionTimeOfWeek: fb.group({
      dayOfWeek: ['mon'],
      hour: [0],
      minute: [0],
      timeZone: [null]
    }),
    nextHiringStatus: [null],
    nextHiringStatusDelayInMinutes: [null]
  });
}

export function setJobTriggerFormArray(triggers: Trigger[]): FormArray {
  const formArray = fb.array<FormGroup>([]);

  triggers
    .forEach((trigger: Trigger) => {
      const form = createJobTriggerForm();
      form.patchValue(trigger);
      formArray.push(form);
    });

  return formArray;
}

export function initializeHigherJobForm(): FormGroup {
  return fb.group({
    jobTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    branch: fb.control(null, [Validators.required]),
    jobLocation: fb.control(null),
    applicationDate: fb.control(null, [Validators.required]),
    responsiblePerson: fb.control({ value: null, disabled: true }, [Validators.required]),
    hiddenFromUsers: fb.control(null),
  });
}

export function initializeFacebookAndInstagramJobForm(): FormGroup {
  return fb.group({
    facebookAndInstagramTitle: fb.control(null, [Validators.required, Validators.maxLength(75)]),
    shortJobDescription: fb.control(null, [Validators.required]),
    facebookAndInstagramLocation: fb.control(null, [Validators.required]),
    costCenter: fb.control(null, [Validators.maxLength(costCenterMaxLength)])
  });
}
